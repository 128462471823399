import React from "react";
import Pattern from "../../../images/panel_long_1.png";
import './index.scss';
import { CaptionImg } from "../../CaptionImg/captionImg"

export const Section3b = ({ imgLeftSrc, imgRightSrc, txtLeft, txtRight, imgLeftCaption, imgRightCaption, customSectionClass }) => {
  return (
    <div className={`m-main__section m-section__3 ${customSectionClass ? customSectionClass : ''}`}>
      <div className="m-section__3-bg" style={{ backgroundImage: `url(${Pattern})` }}></div>
      <div className="m-section__3-content m-section__3b-content">
        <div className={`m-section__3-left m-section__3-flex`}>
          <CaptionImg imgSrc={imgLeftSrc}
                      customClass="m-section__3-img m-section__3b-img"
                      caption={imgLeftCaption}
          />
          <p className="m-main__section-text m-main__section-3b-text">
            {txtLeft}
          </p>
        </div>
        <div className={`m-section__3-flex`}>
          <CaptionImg imgSrc={imgRightSrc}
                      customClass="m-section__3-img m-section__3b-img"
                      caption={imgRightCaption}
          />
          <p className="m-main__section-text m-main__section-3b-text">
            {txtRight}
          </p>
        </div>
      </div>
    </div>
  )
}
