import React, { useEffect, useRef, useState } from "react"
import Layout from "../components/layout";
import "./index.scss";
import Pattern from "../images/svg/panel_long_1.svg";
import { graphql } from "gatsby";
import { ChapterTitle } from "../components/Sections/ChapterTitle/chapterTitle"
import { getResource } from "../utils/getResource"
import { Section3 } from "../components/Sections/Section3/section3"
import { Section1 } from "../components/Sections/Section1/section1"
import { Section2 } from "../components/Sections/Section2/section2"
import { Section5 } from "../components/Sections/Section5/section5"
import { Section3b } from "../components/Sections/Section3/section3b"
import { PageNavigation } from "../components/PageNavigation/pageNavigation"

const Remembrance = ({ data, location }) => {
  const pageResources = data.allMarkdownRemark.edges;
  const remembranceRefs = useRef([])
  const [pageRefs, setPageRefs] = useState([])

  useEffect(() => {
    const pageRefsArr = remembranceRefs.current.map(ref => ({ id: ref.id, offset: ref.offsetTop, height: ref.offsetHeight }))

    setPageRefs(pageRefsArr)
  }, [])

  return (
    <Layout location={location} pageRefs={pageRefs} sectionClass="m-main__content-remembrance">
      <div className="m-main__content">
        <div className="m-main__section-pattern">
          <Pattern />
        </div>
        <ChapterTitle text={getResource(pageResources, 'remembrance-title').content} bgImage={getResource(pageResources, 'remembrance-title').bgImage} />
        <div ref={el => remembranceRefs.current[0] = el} id="exhibition-remembrance-main">
          <Section1 text={getResource(pageResources, 'remembrance-text-64').content} imgCaption={getResource(pageResources, 'remembrance-text-64').imageContent} imgSrc={getResource(pageResources, 'remembrance-text-64').image} centered isReversed/>
          <Section2 option={'textOnly'}
                    bgImg={getResource(pageResources, 'remembrance-text-65').bgImage}
                    caption={getResource(pageResources, 'remembrance-text-65').imageContent}
                    text={getResource(pageResources, 'remembrance-text-65').content}
          />
          <Section1 text={getResource(pageResources, 'remembrance-text-66').content}
                    imgCaption={getResource(pageResources, 'remembrance-text-66').imageContent}
                    imgSrc={getResource(pageResources, 'remembrance-text-66').image}
                    centered
          />
          <Section2 option={'twoColumns'}
                    bgImg={getResource(pageResources, 'remembrance-text-67').bgImage}
                    caption={getResource(pageResources, 'remembrance-text-67').imageContent}
                    imgSrc={getResource(pageResources, 'remembrance-text-67').image}
                    text={getResource(pageResources, 'remembrance-text-67').content}
                    isReversed
          />
          <Section5 imgSrc={getResource(pageResources, 'remembrance-img-1').image} caption={getResource(pageResources, 'remembrance-img-1').imageContent} />
          <Section3b imgLeftSrc={getResource(pageResources, 'remembrance-img-2').image}
                     imgRightSrc={getResource(pageResources, 'remembrance-img-3').image}
                     imgLeftCaption={getResource(pageResources, 'remembrance-img-2').imageContent}
                     imgRightCaption={getResource(pageResources, 'remembrance-img-3').imageContent}
                     customSectionClass="m-section__culture-img-only"
          />
          <Section2 option={'movie'}
                    bgImg={getResource(pageResources, 'remembrance-mov-1').bgImage}
                    movieSrc={getResource(pageResources, 'remembrance-mov-1').content}
          />
          <Section3 imgLeftSrc={getResource(pageResources, 'remembrance-text-68').image}
                    imgRightSrc={getResource(pageResources, 'remembrance-text-69').image}
                    txtLeft={getResource(pageResources, 'remembrance-text-68').content}
                    txtRight={getResource(pageResources, 'remembrance-text-69').content}
                    imgLeftCaption={getResource(pageResources, 'remembrance-text-68').imageContent}
                    imgRightCaption={getResource(pageResources, 'remembrance-text-69').imageContent}
                    imgRightFull
          />
          <Section5 imgSrc={getResource(pageResources, 'remembrance-img-4').image} caption={getResource(pageResources, 'remembrance-img-4').imageContent} />
          <Section2 option={'movie'}
                    bgImg={getResource(pageResources, 'remembrance-mov-2').bgImage}
                    movieSrc={getResource(pageResources, 'remembrance-mov-2').content}
                    customSectionClass="m-section__remembrance-movie-2"
          />
          <PageNavigation prevOnly prevItem={'exhibition-culture'} />

          <div className="m-main__section-pattern">
            <Pattern />
          </div>
          <div className="m-section__authors-container">
            <ChapterTitle text={getResource(pageResources, 'remembrance-authors').imageContent} />
            <p className="m-section__authors-text" dangerouslySetInnerHTML={{ __html: getResource(pageResources, 'remembrance-authors').content}}>
            </p>
          </div>
          <div className="m-section__organisation-logos" style={{ backgroundImage: `url(${getResource(pageResources, 'remembrance-organisations-text').image})` }}></div>
        </div>
      </div>
    </Layout>
  )
}

export default Remembrance

export const pageQuery = graphql`
query($language: String) {
  allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "remembrance-item"}, language: { eq: $language }}}
  ) {
    edges {
      node {
        frontmatter {
          title
          language
          content
          image
          imageContent
          bgImage
        }
      }
    }
  }
}`
